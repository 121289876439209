import React, { useState } from 'react';
import './nav.css';
import { IoIosLogIn } from "react-icons/io";
import { Link } from 'react-router-dom';
import brain from './assets/chevron.png';

const Nav = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    return (
        <nav className="nav">
            <img src={brain} alt="Logo" className="nav-logo" />

            <div className="burger-menu" onClick={() => setIsNavExpanded(!isNavExpanded)}>
                <div className="burger-line"></div>
                <div className="burger-line"></div>
                <div className="burger-line"></div>
            </div>

            <ul className={isNavExpanded ? "nav-links expanded" : "nav-links"}>
                <li><Link to="/#top" onClick={() => setIsNavExpanded(false)}>Home</Link></li>
                <li><Link to="/#our-courses" onClick={() => setIsNavExpanded(false)}>Courses</Link></li>
                <li><Link to="/#funded-by-dfe" onClick={() => setIsNavExpanded(false)}>Funding</Link></li>
                <li><Link to="/" onClick={() => setIsNavExpanded(false)}>Tools</Link></li>
                <li><Link to="/" onClick={() => setIsNavExpanded(false)}>Blog</Link></li>
                <li><Link to="/" className='login-button' onClick={() => setIsNavExpanded(false)}><IoIosLogIn /></Link></li>
                <li><Link to="/#contact-us" className='apply-button' onClick={() => setIsNavExpanded(false)}>Apply</Link></li>
            </ul>
        </nav>
    );
};

export default Nav;
