import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from './assets/SC_green_white.png'
import flyer from './assets/SC-AIML-2425-min.webp'
import flyerDL from './assets/SC-AIML-2425-full.png'
import './landing.css';

const Landing = () => {
    const location = useLocation();
    const [showPopup, setShowPopup] = useState(false);

    const openPopup = () => setShowPopup(true);
    const closePopup = () => setShowPopup(false);

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]); // Depend on location to re-run the effect when it changes

    return (
        <div className="parallax-page" id="top">
            <div className="parallax-section for-business" id="for-business">
                <div className="overlay">
                    <div>
                        {/* <div className="bubble bubble-primary">Innovative Solutions</div>
                        <div className="bubble bubble-secondary">Cutting-edge Technology</div> */}
                        <img className="logo" src={logo} alt="company logo"></img>
                        <div className='scroll-to-learn'>
                            <p>Scroll to learn more</p>
                            {/* <p>˅</p>
                            <p>˅</p>
                            <p>˅</p> */}
                            <p className="down-arrow">&#8595;</p>
                            <p className="down-arrow">&#8595;</p>
                            <p className="down-arrow">&#8595;</p>
                        </div>
                        {/* More bubbles and content */}
                    </div>
                </div>
            </div>
            <div className="non-parallax-section">
                <div className="non-parallax-content-area">
                    <h1>Business Focused.</h1>
                    <p>Our bespoke training solutions are designed to upskill your existing workforce, optimising their performance and fostering a culture of continuous improvement.</p>
                    <p>With Source Coding, you gain access to a wellspring of expertise, paving the way for innovative solutions and a competitive edge in the ever-evolving tech landscape.</p>
                    <p>We ensure that our content and curriculum directly aligns with your specific business needs so that each course graduate has project work ready (with an average value of <strong>~£15,000</strong>) for direct deployment in their workplace – meaning your employees continue to work towards your business goals, even as they study.
                    </p>
                    <p>Join forces with us, and let's cultivate excellence together, driving your business towards new heights of success.</p>
                    <button class="modern-button">Learn more</button>
                    {/* More bubbles and content */}
                </div>
            </div>
            <div className="parallax-section our-courses" id="our-courses">
                <div className="overlay">
                    <div className='text-panel'>
                        <h1>Our Courses.</h1>
                        <p>Our courses are designed for businesses looking to up-skill their existing workforce.</p>
                        <p>Alongside our core curriculum, our courses are tailored directly for <strong>you</strong>, and your specific business needs.</p>
                        <p>At the end you'll be left with a deployable product, <strong>bespoke to you</strong>, alongside enhanced in-house capabilities for your workforce.
                        </p>
                        <div class="card-container">
                            <div class="card card1">
                                <div class="card-header">Artificial Intelligence and Machine Learning</div>
                                <div class="card-body">
                                    <p>6-week, part time, and bespoke to you.</p>
                                    <button className="card-button" onClick={openPopup}>Learn More</button>
                                    <a href={flyerDL} download="SC AI & ML Flyer" className="card-button download-button">Full Flyer</a>
                                </div>
                            </div>
                            <div class="card card2">
                                <div class="card-header">Coming soon</div>
                                <div class="card-body">
                                    <p>Stay tuned, it's going to be good...</p>
                                    <button class="card-button">Learn More</button>
                                </div>
                            </div>
                            <div class="card card3">
                                <div class="card-header">Coming soon</div>
                                <div class="card-body">
                                    <p>Stay tuned, it's going to be good....</p>
                                    <button class="card-button">Learn More</button>
                                </div>
                            </div>
                        </div>
                        {showPopup && (
                            <div className="popup">
                                <div className="popup-content">
                                    <img src={flyer} alt="Flyer" />
                                    <button className="close-button" onClick={closePopup}>X</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="non-parallax-section funded-by-dfe" id="funded-by-dfe">
                <div className="non-parallax-content-area">
                    <div>
                        <h1>Funded by the DfE.</h1>
                        <p>Our courses are funded by the Department for Education and endorsed by Local Enterprise Partnerships.</p>
                        <p>For SME’s that meet our funding criteria we are able to offer up-to 90% funding for each course, and up-to 70% for larger organisations.</p>
                        <div className='table-div'>
                            <p>Example breakdown based on a £2,000 course cost:</p>
                            <table>
                                <tr>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </tr>
                                <tr>
                                    <td>Course cost per learner</td>
                                    <td>£2000</td>
                                </tr>
                                <tr>
                                    <td>Funding reduction</td>
                                    <td>£1800 (90%)</td>
                                </tr>
                                <tr>
                                    <td>Price payable per learner</td>
                                    <td>£200</td>
                                </tr>
                            </table>
                        </div>
                        <button class="modern-button">Learn more</button>
                    </div>
                </div>
            </div>
            <div className="parallax-section why-source-coding" id="why-source-coding">
                <div className="overlay">
                    <div className='why-source-coding-content'>
                        <div className='text-panel why-panel'>
                            <h1>Why Source Coding?</h1>
                            <p>At Source Coding we believe in the power of technology to transform lives and shape the future, our goal is to empower individuals with the skills and knowledge to excel in this ever-evolving landscape.</p>
                            <h2>Cutting-edge, and dedicated.</h2>
                            <p>we pride ourselves on offering cutting-edge courses which are tailored to meet the needs of a diverse range of learners.</p>
                            <p>Our hands-on, project-based approach ensures that our learners not only acquire the essential technical skills but  also develop the critical thinking and problem-solving abilities necessary to thrive in today's fast-paced tech landscape.</p>
                            <p>We are dedicated to fostering a supportive, and inclusive, learning environment where students from all backgrounds can come together, collaborate, and grow. Our team of experienced instructors is committed to providing personalised guidance and mentorship, helping each student achieve their full potential.</p>
                            <h2 className='why-remove'>Growth mindset.</h2>
                            <p className='why-remove'>As we continue to grow and evolve, we remain steadfast in our commitment to our core values of growth mindset, lifelong learning, and making a difference in the lives of our learners. We are proud to have played a part in the success stories of our graduates and look forward to shaping the future of technology, one learner at a time.</p>
                            <p className='why-remove'>Join us on this exciting journey, and together, let's unlock new possibilities and create a world where everyone can harness the full power of technology to make a meaningful impact.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="non-parallax-section contact-us" id="contact-us">
                <div className="non-parallax-content-area">
                    <div className='form-parent'>
                        <h1>Contact us</h1>
                        <form name="contact" method="POST" className="contact-form">
                            <input
                                type='hidden'
                                name='form-name'
                                value='contact' />

                            <label htmlFor="name">Name</label>
                            <input id="name" placeholder="Your name..." type="text" name="name" />

                            <label htmlFor="email">Email</label>
                            <input id="email" placeholder="Your email..." type="email" name="email" />

                            <label htmlFor="query-type">Query Type</label>
                            <select id="query-type" name="queryType">
                                <option value="general">General Query</option>
                                <option value="course">Course Query</option>
                                <option value="enrolment">Enrolment Query</option>
                            </select>

                            <label htmlFor="subject">Subject</label>
                            <input id="subject" placeholder="Your subject..." type="text" name="subject" />

                            <label htmlFor="message">Message</label>
                            <textarea id="message" placeholder="Your message..." name="message"></textarea>

                            <button type="submit" className="submit-button">Send</button>
                        </form>
                    </div>
                </div>
            </div>
            {/* Repeat for other sections */}

            <footer className="modern-footer">
                {/* Footer content */}
            </footer>
        </div>
    );
};

export default Landing;
