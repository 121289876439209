import React from 'react';
import './App.css';
import Landing from './components/landing/Landing';
import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* Add other Routes as needed */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
