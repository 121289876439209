import React, { useEffect, useState } from 'react';
import DCC from './assets/s960_committed_small.png'
import './footer.css';
// Import icons from a library like react-icons or similar
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaGithub } from 'react-icons/fa';

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
        setIsVisible(bottom);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <footer id="footer" style={{ bottom: isVisible ? '0' : '-100px' }}>
            <div className="footer-left">
                <img src={DCC} alt="DCC" className="footer-image" />
            </div>
            <div className="footer-content">
                <p><strong>info@sourcecoding.co.uk</strong></p>
                {/* Additional footer content */}
                <div className="social-media-icons">
                    <FaFacebook />
                    <FaTwitter />
                    <FaLinkedin />
                    <FaInstagram />
                    <FaGithub />
                    {/* Add more icons as needed */}
                </div>
            </div>
            {/* <div className="footer-right">
                <p>Source Coding © 2023 - Source Coding is a registered trademark, all rights reserved.</p>
            </div> */}
        </footer>
    );
};

export default Footer;
